html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	background: transparent;
	font-size: 100%;
	font-weight: lighter;
	vertical-align: baseline;
}

html{
	overflow-x: hidden;
}
body {
	line-height: 1;
	color: #333;
	font-family: $font-principal;
	background-size: 30%;
	background-position: top;
	@media (max-width: 767px) {
		line-height: 1.3;
		background-size: 100%;		
	}
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
nav ul {
	list-style: none;
}

ul li{
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
a {
	margin: 0;
	padding: 0;
	background: transparent;
	vertical-align: baseline;
	text-decoration: none;
	font-size: 100%;
}
ins {
	background: #ff9;
	text-decoration: none;
	color: #000;
}
mark {
	font-style: italic;
	font-weight: bold;
	background: #ff9;
	color: #000;
}
del {
	text-decoration: line-through;
}
abbr[title], dfn[title] {
	border-bottom: 1px dotted;
	cursor: help;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
hr {
	height: 1px;
	display: block;
	margin: 1em 0;
	padding: 0;
	border: 0;
	border-top: 1px solid #cccccc;
}
input, select {
	vertical-align: middle;
}
img {
	width: 100%;
	max-width: 100%;
	height: auto;
	display: block;
}
* {
	box-sizing: border-box;
}
*:focus {
	outline: none;
}
@include component('container'){
	width: 90%;
	max-width: 1366px;
	display: block;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
}
@include component('itemVideo'){
	height: 0;
	padding-bottom: 56.25%;
	position: relative;
	overflow: hidden;
	video, embed, iframe, object {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
}

.TypeTitle{
	width: 100%;
	margin: 40px auto;
	text-align: center;
	font-weight: 900;
    font-size: 40px;
	color: #444;
	
	h2{
		span{
			color: #0af;
			display: block;
			font-weight: 300;
		}
	}
}