@include component('BannerFull'){
    display: block;
    height: 700px;
    overflow: hidden;
    transition: all .4s;
    position: relative;

    @media (max-width: 480px){
        height: 500px;
    }

    .figure-home-1{
        position: absolute;
        top: 60%;
        width: 280px;
        right: 30%;
        z-index: 10;
        //transition: all .4s;
        @media (max-width: 470px){
            display: none;
        }
    }

    .figure-home-2{
        position: absolute;
        top: 10%;
        width: 480px;
        right: -5%;
        z-index: 9;
        //transition: all .4s;

        @media (max-width: 1200px){
            right: -15%;
        }
        @media (max-width: 900px){
            right: -30%;
        }
        @media (max-width: 900px){
            right: -50%;
        }
        @media (max-width: 500px){
            right: -70%;
        }
        @media (max-width: 470px){
            right: -95%;
        }
    }
    .figure-home-3{
        position: absolute;
        top: 5%;
        width: 180px;
        right: 40%;
        z-index: 9;
        //transition: all .4s;
    }
    .figure-home-4{
        position: absolute;
        top: 10%;
        width: 480px;
        left: -10%;
        z-index: 9;
        //transition: all .4s;

        @media (max-width: 1200px){
            left: -30%;
        }
        @media (max-width: 900px){
            left: -45%;
        }
        @media (max-width: 700px){
            left: -70%;
        }
        @media (max-width: 500px){
            left: -80%;
        }
        @media (max-width: 470px){
            left: -130%;
        }
    }

   &:before{
       content: "";
       background-image: url(../img/logo-white.svg);
       //background-size: 100%;
       position: absolute;
       bottom: -12%;
       left: 0;
       width: 100%;
       height: 50%;
       z-index: 9;
       opacity: .05;
       animation: positiontext 300s linear infinite;
       @keyframes positiontext {
           0% {
               background-position: 500% 0;
           }
           
       }
   }
    &:after{
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //background: linear-gradient(to right, #f66aff, #c385ff, #8b97ff, #50a3ff, #00aaff);
        background-image: url(../img/bg-home.png);
        position: absolute;
        z-index: 8;
        opacity: 1;
        background-size: cover; 
        //animation: gradientBG 10s ease infinite;
        //background-size: 150% 150%;
        /*@keyframes gradientBG {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }*/
    }

    @include element('bg'){
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        display: flex;
        height: 100%;
        img{
            object-fit: cover;
            position: relative;
        }
        video{
            width: 100%;
            //height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    @include element('text'){
        position: relative;
        top: 40%;
        left: 0;
        z-index: 10;
        //transform: translateX(-50%) translateY(-50%);
        width: 100%;
        h1{
            color: #fff;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 90px;
            max-width: 50%;
            line-height: 80px;
            @media (max-width: 480px){
                max-width: 100%;
            }
            span{
                display: block;
                font-size: 15px;
                font-weight: bold;
                color: #f4f4f4;
                letter-spacing: 1px;
                line-height: 14px;
            }

            @media (max-width: 580px){
                font-size: 70px;
                line-height: 60px;
            }
            @media (max-width: 480px){
                font-size: 50px;
                line-height: 40px;
                span{
                    line-height: 20px;
                }
            }
        }

        a{
            color: #fff;
            display: block;
            height: 40px;
            line-height: 40px;
            margin: 20px 0;

            &:hover{
                color: #42d0ff;
            }
        }
    }
}


@include component('BoxService'){
    display: block;
    overflow: hidden;
    height: auto;
    @include element('content'){
        display: flex;
        flex-wrap: wrap;
        margin: 30px auto;
        width: 100%;
        padding: 5px;

        @media(max-width: 768px){
            display: block;
        }
    }

    @include element('items'){
        display: block;
        background: #fff;
        margin-right: 2%;
        margin-bottom: 2%;
        overflow: hidden;
        width: 32%;
        box-shadow: 0 0 11px 0 rgba(71,71,71,.1);
        //padding: 20px;
        color: #666;

        
        &:hover{
            .figure__service{
                img{
                    width: 110%;
                    max-width: 110%;
                    transition: all .4s;
                }
            }
        }
        .figure__service{
            display: block;
            height: auto;
            padding-bottom: 56.3%;
            position: relative;
            overflow: hidden;
            img{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 100%;
                height: auto;
                transition: all .4s;
            } 
        }
        

        .text__service{
            padding: 20px;
        }

        &:nth-child(3n){
            margin-right: 0;
        }
        h2{
            font-size: 24px;
            color: #32325d;
        }
        p{
            margin: 20px auto;
            font-size: 15px;
            line-height: 24px;
        }
        a{
            color: #42d0ff;
            font-weight: bold;
        }

        @media(max-width: 768px){
            width: 100%;
            margin-right: 0;
        }
    }
}


@include component('BoxEcommerce'){
    width: 100%;
    position: relative;
    overflow: hidden;
    display: block;
    height: 100%;
    padding: 70px 0;

    h2{
        //color: #0af;
        font-size: 35px;
        margin-bottom: 40px; 
        line-height: 40px;
        color: #666;
        ins{
            background: #F66AFF;
            text-decoration: none;
            padding: 3px;
            color: #FFFFFF;
            display: inline-block;
        }
    }

    @include element('content'){
        overflow: hidden;
        height: auto;
    }

    @include element('items'){
        display: block;
        width: 50%;
        @media(max-width: 950px){
            width: 100%;
            position: relative;
        }
        p{
            line-height: 25px;
            margin-top: 20px;
            font-size: 16px;
        }
    }

    @include element('gallery'){
        overflow: hidden;
        height: auto;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 100px 0;
        padding: 5px;

        a{
            width: 23.5%;
            display: block;
            float: left;
            margin-right: 2%;
            margin-bottom: 2%;
            margin-top: 2%;
            padding: 10px;
            position: relative;
            &:hover{
                box-shadow: 0 0 11px 0 rgba(71,71,71,.2);
            }

            img{
                display: block;
                position: relative;
                width: 90%;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }

            &:nth-child(4n){
                margin-right: 0;
            }

            @media(max-width: 990px){
                width: 49%;
                &:nth-child(2n){
                    margin-right: 0;
                }
            }
        }
    }
}
@include component('BoxContent'){
    width: 100%;
    position: relative;
    overflow: hidden;
    display: block;
    height: 100%;
    background: #f6f9fc;

    @include element('content'){
        display: block;
        width: 100%;
        height: auto;
        overflow: hidden;
        padding: 70px 0;
    }

    @include element('items'){
        display: block;
        width: 50%;

        @media(max-width: 950px){
            width: 100%;
            position: relative;
        }
        .LinkDirect{
            display: inline-flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background: #00aaff;
            padding: 4px 15px;
            margin-bottom: 100px;
            color: #fff;
            border-radius: 15px;
            margin-top: 0;

            a{
                background: greenyellow;
                color: #fff;
                padding: 3px 15px;
                border-radius: 15px;
                &:hover{
                    background: green;
                }
            }
        }
        h2{
            font-weight: 900;
            font-size: 40px;
            color: #444444;
            span{
                font-size: 25px;
                display: block;
                color: #00aaff; 
                margin-bottom: 15px;
            }
        }
        p{
            line-height: 25px;
            margin-top: 20px;
            font-size: 16px;
        }
    }
}

@include component('BackgroundDevice'){
    overflow: hidden;
    width: 100%;
    height: auto;
    position: relative;
    padding: 70px 0 0 0;
    top: -100px;
}
@include component('BrandSite'){
    position: absolute;
    display: block;
    transform: rotate(-25deg);
    width: 50%;
    right: -10%;
    transition: all .3s;
    transition-timing-function: ease-out;
    overflow: hidden;
    top: 85px;
    @media(max-width: 1100px){
        top: 130%;
    }

    @media(max-width: 950px){
        transform: rotate(0);
        width: 100%;
        position: relative;
        right: 0;
    }

    img{
        display: block;
        height: 100%;
    }
}


@include component('BoxCustomers'){
    width: 100%;
    position: relative;
    overflow: hidden;
    display: block;
    height: 100%;
    background: #f6f9fc;
    top: -100px;

    .TypeTitle{
        margin-bottom: 0;
    }

    @include element('content'){
        display: flex;
        width: 100%;
        height: auto;
        overflow: hidden;
        padding: 30px 0;
        flex-wrap: wrap;

        &::before{
            content: "";
            width: 1px;
            height: 100%;
            position: absolute;
            top: 90%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            background: #f6f9fc - 10;
        }

        .item{
            float: left;
        }
    }

    @include element('gallerybrand'){
        display: block;
        margin: 40px auto;


        .item{
            display: block;
            float: left;
            width: 18.4%;
            margin-right: 2%;
            margin-bottom: 2%;
            border: 1px solid #f6f9fc - 20;
            box-sizing: border-box;
            padding: 10px;

           

            img{
                display: block;
                width: 90%;
                margin: 0 auto;
                filter: grayscale(90%);
            }

            &:hover{
                background: #fff;
                box-shadow: 0 0 11px 0 rgba(71,71,71,.1);
                img{
                    filter: grayscale(0);
                }
            }

            &:nth-child(5n){
                margin-right: 0;
            }

            @media(max-width: 768px){
                width: 32%;
                &:nth-child(3n){
                    margin-right: 0;
                }
                &:nth-child(5){
                    margin-right: 2%;
                }
            }

            @media(max-width: 588px){
                width: 49%;
                &:nth-child(3n){
                    margin-right: 2%;
                }
                &:nth-child(5){
                    margin-right: 2%;
                }
                &:nth-child(2n){
                    margin-right: 0;
                }
            }
        }
    }
    @include element('items'){
        width: 49%;
        float: left;
        display: block;
        margin-right: 2%;
        padding: 30px;
        box-sizing: border-box;

        &:nth-child(2n){
            margin-right: 0;
        }

        @media(max-width: 768px){
            width: 100%;
            margin-right: 0;
            margin-bottom: 2%;
        }

        p{
            line-height: 25px;
        }

        i{
            background: #42d0ff;
            width: 70px;
            height: 70px;
            text-align: center;
            line-height: 70px;
            border-radius: 100%;
            margin: 30px 0;
            display: block;
            font-size: 30px;
            color: #42d0ff - 50;
        }

        h2{
            margin-bottom: 30px;
            color: #42d0ff;
            font-size: 30px;
        }
    }
}

.TopUp{
    position: relative;
    top: -100px;
    z-index: 9;
}
.NoTopUp{
    top: 0;
}

.White{
    background: #fff;
}