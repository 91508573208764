@include component('BannerAbout'){
    display: block;
    overflow: hidden;
    height: auto;
    margin-top: 60px;
    position: relative;
    padding-bottom: 70px;


    @include element('bgparallax'){
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        transform-style: preserve-3d;
        padding-top:20vh;
        //transition: all .4s;
        &:before{
            content: "";
            background-image: url(../img/bg-somos.svg);
            width: 100vw;
            height: 100vh;
            top:0;
            left:0;
            position: absolute;
            z-index: -1;
            opacity: .3;
            transform:translateZ(-1px) scale(1);
            transform-style: preserve-3d;
            background-attachment: fixed;
        }
    }

 

    .c-itemVideo{
        max-width: 600px;
        margin: 0 auto;
        padding-bottom: 25.25%;
        @media(max-width: 768px){
            padding-bottom: 56.25%;
        }
    }
    .BackgroungImageLeft, .BackgroungImageRight{
        position: absolute;
        top: 45%;
        left: 5%;
        width: 50%;
        max-width: 400px;

        @media(max-width: 768px){
            display: none;
        }
    }

    .BackgroungImageRight{
        left: auto;
        right: 5%;
    }

    @include element('text'){
        display: block;
        width: 90%;
        max-width: 700px;
        margin: 70px auto;
        text-align: center;


        h1{
            font-weight: 900;
            font-size: 40px;
            color: #444;
        }

        p{
            line-height: 25px;
            margin-top: 20px;
            font-size: 16px;
        }
    }
}



@include component('BoxTestimonials'){
    display: block;
    height: auto;
    padding: 40px 0;
    overflow: hidden;
    transition: all .4s;
    position: relative;

    @include element('content'){
        display: block;
    }

    @include element('item'){
        display: block;
        width: 100%;
        overflow: hidden;
        max-width: 100%;
        .CaptureTestimonials{
            background: #f4f4f4;
            box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025);
            border-radius: 4px;
            overflow: hidden;
            position: relative;
            margin: 30px auto 60px auto;
            max-width: 900px;
            display: block;

            .CaptureTestimonials__text{
                display: block;
                width: 50%;
                right: 0;
                top: 0;
                background: rgb(253, 66, 66);
                position: relative;
                height: 100%;
                position: absolute;
                box-sizing: border-box;
                color: #fff;

                
                blockquote{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    transform: translateX(-50%) translateY(-50%);
                    padding: 30px;
                    p{
                        font-size: 18px;
                        line-height: 30px;
                    }
    
                    h2{
                        font-weight: bold;
                        font-size: 40px;
                        margin-bottom: 20px;
                    }

                    .cite{
                        margin-top: 10%;
                        font-weight: bold;
                        span{
                            display: block;
                            font-weight: 300;
                        }
                    }
                }
                @media(max-width: 768px){
                    width: 100%;
                    position: relative;
                    blockquote{
                        position: relative;
                        top: 0;
                        left: 0;
                        transform: translateY(0);
                    }
                }
            }
        }
            
    }
}