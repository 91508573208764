header{
    display: block;
    height: 100px;
    width: 100%;
    position: fixed;
    z-index: 13;
    transition: all .4s;

    .c-container{
        @media(max-width: 580px){
            overflow: inherit;
            width: 100%;
        }
    }
    .DownNav{
        display: none;
        position: absolute;
        width: 100px;
        height: 100px;
        line-height: 100px;
        font-size: 30px;
        top: 0;
        right: 0;
        z-index: 10;
        color: #fff;
        text-align: center;
        box-shadow: 0 0 11px 0 rgba(71,71,71,.1);
        cursor: pointer;
        transition: all .4s;

        &:hover{
            box-shadow: 0 0 11px 0 rgba(71,71,71,.3);
            transition: all .4s;
        }
        @media(max-width: 580px){
            display: block;
        }
    }
}

@include component('Brand'){
    max-width: 160px;
    margin-top: 12px;
    display: block;
    float: left;
    transition: all .4s;
    @media(max-width: 580px){
        margin-left: 5%;
    }

    .LogoBrand{
        display: none;
        width: 300px;
    }
}

@include component('NavPrincipal'){
   float: right;
   display: block;
   height: 100px;
   line-height: 100px;



   ul{
       display: block;
       float: right;
       width: 379px;
      
       li{
           float: left;
           margin-right: 2%;
           width: auto;
           &:last-child{
               margin-right: 0;
               width: 150px;

               a{
                background: #42d0ff;
                height: 40px;
                line-height: 40px;
                color: #fff;
                font-size: 13px;
                width: 100%;
                border-radius: 30px;
                text-align: center;
                margin-top: 28px;
                &:hover{
                    background: #F66AFF;
                    color: #fff;
                }
               }
           }
           a{
               color: #fff;
               display: block;
               height: 100px;
               padding: 0 10px;
               position: relative;
               text-transform: uppercase;
               font-size: 13px;
            

               &:hover{
                   color: #42d0ff;
                   transition: all .4s;
               }
           }
       }
   }


   @media(max-width: 580px){
        display: none;
        height: auto;
        width: 100%;
        background: #fff;
        position: absolute;
        top: 60px;
        z-index: 11;

        ul{
            width: 100%;

            li{
                width: 100%;
                display: block;
                border-top: 1px dotted rgb(240, 240, 240);
                a{
                    padding-left: 5%;
                }

                &:last-child{
                    width: 100%;
                    a{
                        line-height: 60px;
                        height: 60px;
                        background: transparent;
                        color: #333;
                        text-align: left;
                        margin: 0;

                        &:hover{
                            background: transparent;
                            color: #42d0ff;
                        }
                    }
                }
            }
        }
    }
}



@include component('HeaderFixed'){
    background: #fff;
    transition: all .4s;
    height: 60px;
    box-shadow: 0 0 11px 0 rgba(71,71,71,.1);
    top: 0;
    
    .LogoWhite{
        display: none;
    }

    .LogoBrand{
        display: block;
    }

    .c-Brand{
        max-width: 100px;
        transition: all .6s;
    }

    .DownNav{
        width: 60px;
        height: 60px;
        color: #333;
        line-height: 60px;
        font-size: 15px;
        transition: all .4s;
    }

    .c-NavPrincipal{
        height: 60px;
        line-height: 60px;
         ul li a{
             height: 60px;
             color: #333;
             transition: all .4s;
             &:hover{
                 color: #42d0ff;
             }
         }

         ul li{
             &:last-child a{
                 color: #fff;
                 margin-top: 9px;
                 height: 40px;
             }
         }
    }
}


@include component('ButtonCall'){
    display: block;
    width: 180px;
    height: 70px;
    background: #fff;
    color: red;
    position: fixed;
    bottom: 100px;
    right: 0;
    z-index: 99;
    box-shadow: 0 0 11px 0 rgba(71,71,71,.1);
    transform: rotate(-90deg);
    line-height: 70px;
    text-align: center;
    right: -53px;
    
    a{
       
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        color: #42d0ff;
        display: block;
        transition: all .4s;
        font-size: 18px;
        letter-spacing: 1px;
        &:hover{
            background: #42d0ff;
            color: #fff;
            transition: all .4s;
        }
    }
}



.Nav-Mobile{
    display: none;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    //background: rgba(0,0,0, .9);
    background-image: linear-gradient(to right top, #733a8bf1, #633285dd, #512a7ef1, #3f2378f1, #291d71f1);

    .navclose{
        display: block;
        width: 60px;
        height: 60px;
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        text-align: center;
        line-height: 60px;
        cursor: pointer;
        font-size: 60px;
    }

    nav{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;

        ul{
            display: block;
            width: 100%;
            li{
                display: block;
                width: 100%;
                text-align: center;
                margin: 4%;

                a{
                    color: #fff;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-size: 40px;
                    line-height: 80px;
                    width: 100%;
                    display: block;
                    transition: all .4s;

                    &:hover{
                        padding-left: 5%;
                    }
                }
            }
        }
    }
}
