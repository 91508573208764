@include component('BannerContact'){
    display: block;
    overflow: hidden;
    height: auto;
    margin-top: 60px;
    position: relative;
    padding-bottom: 70px;


    @include element('bgparallax'){
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        transform-style: preserve-3d;
        padding-top:20vh;
        transition: all .4s;
        &:before{
            content: "";
            background-image: url(../img/bg-contacto.svg);
            width: 100vw;
            height: 100vh;
            top:0;
            left:0;
            position: absolute;
            z-index: -1;
            opacity: .3;
            transform:translateZ(-1px) scale(1);
            transform-style: preserve-3d;
            background-attachment: fixed;
        }
    }

    @include element('text'){
        display: block;
        width: 90%;
        max-width: 900px;
        margin: 70px auto;
        text-align: center;


        h1{
            font-weight: 900;
            font-size: 40px;
            color: #444;
        }

        p{
            line-height: 35px;
            margin-top: 20px;
            font-size: 30px;
        }
    }
}


@include component('BoxContact'){
    display: block;
    padding: 50px 0;
    width: 100%;
    background-image: linear-gradient(to right top, #00aaff, #00a2f4, #009be8, #0093dd, #008cd2);
    position: relative;

    .c-container{
        overflow: inherit;
    }
    @include element('content'){
        display: block;
        position: relative;
        top: -150px;
        height: 300px;
        z-index: 9;
        max-width: 900px;
        margin: 0 auto;
        .AddresContent{
            padding: 40px;
            box-sizing: border-box;
            float: right;
            margin: 80px auto 0 auto;
            color: #fff;
            width: 50%;
            font-size: 16px;

            @media(max-width: 800px){
                color:#42d0ff;
                width: 100%;
                padding: 0;
            }

           ul{
               display: block;
               overflow: hidden;
               padding: 10px 0;
               @media(max-width: 800px){
                    padding: 20px;
                    box-shadow: 0 0 11px 0 rgba(71,71,71,.1);
                }

               li{
                    display: block;
                    width: 100%;
                    float: left;
                    position: relative;
                    a{
                        color: #fff;
                        display: inline-block;
                        @media(max-width: 800px){
                            color:#42d0ff;;
                        }

                        &:hover{
                            opacity: .7;
                        }
                        span.ico{
                            color: #fff;
                            margin-bottom: 20px;
                            text-transform: none;
                            min-height: 60px;
                            display: inline-block;
                            border: 2px solid #fff;
                            width: 60px;
                            border-radius: 100%;
                            font-size: 20px;
                            @media(max-width: 800px){
                                color:#42d0ff;;
                                border: 2px solid #42d0ff;;
                            }
                            i{
                                text-align: center;
                                width: 100%;
                                display: block;
                                height: 60px;
                                line-height: 60px;
                            }
                        }
                        .clrx{
                            margin-top: 4px;
                            display: inline-block;
                            float: left;
                            clear: inherit;
                            padding-left: 15px;
                            font-weight: 100;
                            font-size: 17px;
                            color: #fff;
                            position: absolute;
                            @media(max-width: 800px){
                                color: #42d0ff;
                            }
                            strong{
                                font-size: 30px;
                                font-weight: 400;
                                display: block;
                                @media(max-width: 800px){
                                    font-size: 20px;
                                }
                            }
                        }
                    }
               }
           }
        }
        .FormContact{
            background: #fff;
            display: block;
            float: left;
            width: 50%;
            overflow: hidden;
            box-shadow: 0 0 11px 0 rgba(71,71,71,.1);
            box-sizing: border-box;
            padding: 25px;

            @media(max-width: 800px){
                width: 100%;
            }

            .formgroup{
                display: block;
                margin-bottom: 20px;


                .error{
                    color: rgb(247, 134, 134);
                    margin-top: 4px;
                    display: block;
                    font-size: 12px;
                }

                label{
                    color: #424770;
                    display: block;
                    //font-weight: 500;
                    margin-bottom: 7px;
                }

                input, textarea{
                    background-color: #f6f9fc;
                    border: 0;
                    height: 40px;
                    width: 100%;
                    border-radius: 5px;
                    box-sizing: border-box;
                    padding: 15px;
                    font-size: 14px;

                    &:focus{
                        box-shadow: 0 0 0 1px #e4effa;
                        color: #8898aa;
                        background: #fff;
                    }
                }

                textarea{
                    height: 100px;
                    resize: none;
                }

                input[type="submit"], button{
                    background: #424770;
                    color: #fff;
                    max-width: 200px;
                    width: 90%;
                    float: right;
                    height: 40px;
                    line-height: 13px;
                    font-weight: bold;
                    text-transform: uppercase;
                    cursor: pointer;
                    border: 0;
                    border-radius: 10px;

                    &:hover{
                        background: #424770 + 10;
                    }
                }
            }
        }
    }
}


@include component('Exito'){
    display: block;
    padding: 200px 0;
    h1{
        color: #181818;
    }
}