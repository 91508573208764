@include component('BannerWork'){
    display: block;
    overflow: hidden;
    height: auto;
    //margin-top: 60px;
    position: relative;
    //padding-bottom: 70px;
    //padding-top: 70px;
    //background: url(../img/bg-work.svg);
    //background: url("https://www.bigbuda.cl/wp-content/uploads/2019/01/header-la-agencia.jpg");
    background-size: cover;

    .figure-home-1{
        position: absolute;
        top: 60%;
        width: 280px;
        right: 30%;
        z-index: 10;
        //transition: all .4s;
        @media (max-width: 470px){
            display: none;
        }
    }

    .figure-home-2{
        position: absolute;
        top: -20%;
        width: 480px;
        right: -5%;
        z-index: 9;
        //transition: all .4s;

        @media (max-width: 1200px){
            right: -15%;
        }
        @media (max-width: 900px){
            right: -30%;
        }
        @media (max-width: 900px){
            right: -50%;
        }
        @media (max-width: 500px){
            right: -70%;
        }
        @media (max-width: 470px){
            right: -99%;
        }
        @media (max-width: 380px){
            right: -120%;
        }
    }
    .figure-home-3{
        position: absolute;
        top: 5%;
        width: 180px;
        right: 40%;
        z-index: 9;
        //transition: all .4s;
    }
    .figure-home-4{
        position: absolute;
        top: 10%;
        width: 480px;
        left: -10%;
        z-index: 9;
        //transition: all .4s;

        @media (max-width: 1200px){
            left: -30%;
        }
        @media (max-width: 900px){
            left: -45%;
        }
        @media (max-width: 700px){
            left: -70%;
        }
        @media (max-width: 500px){
            left: -80%;
        }
        @media (max-width: 470px){
            left: -130%;
        }
    }

    &:before{
        content: "";
        position: absolute;
        top: -50%;
        left: 0;
        height: 100%;
        width: 100%;
        background: url(../img/bg-home.svg);
        background-size: cover;
        background-position: bottom left;
        @media(max-width: 500px){
            top: -60%;
        }
    }
    @include element('text'){
        display: block;
        width: 100%;
        //max-width: 1000px;
        margin: 70px auto;
        text-align: center;
        position: relative;
        z-index: 11;


        h1{
            color: #fff;
            font-weight: 900;
            text-transform: uppercase;
            //line-height: 80px;
            font-size: 200px;
            text-shadow: 5px 3px 10px rgba(71,71,71,.2);
            //text-align: center;

            span{
                color: #1c1c5a;
                //color: #fff;
                display: block;
                font-size: 40px;
                text-transform: none;
                font-weight: 100;

            }

            @media(max-width: 600px){
                font-size: 120px;
            }
            @media(max-width: 400px){
                font-size: 90px;
            }
        }

        p{
            line-height: 25px;
            margin-top: 20px;
            font-size: 16px;
            color: #fff;
        }
    }
}


@include component('BoxWork'){
    display: block;
    height: auto;
    width: 100%;
    overflow: hidden;
    position: relative;
    background: #f6f9fc;

    @include element('content'){
        display: flex;
        flex-wrap: wrap;
        margin: 30px auto;
        width: 100%;
        padding: 5px;

        @media(max-width: 768px){
            display: block;
        }
    }

    @include element('items'){
        display: block;
        height: auto;
        width: 33.333%;
        float: left;
        box-sizing: border-box;
        //margin-right: 2%;
        //margin-bottom: 2%;
        position: relative;
        padding-bottom: 20%;
        overflow: hidden;

        @media(max-width: 768px){
            width: 100%;
            margin-right: 0;
            padding-bottom: 70%;
        }

        &:nth-child(3n){
            margin-right: 0;
        }

        a{
            transition: all .4s;
            &:hover{
                &:after{
                    content: "";
                    width: 100%;
                    height: 100%;
                    display: block;
                    position: absolute;
                    background-image: linear-gradient(to right top, #733a8bb7, #633285b7, #512a7eb7, #3f2378b7, #291d71b7);
                    z-index: 0;
                    transition: all .4s;
                }
            }
        }

        img{
            width: 100%;
            max-width: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all .4s;
        }

        &:hover{
            img{
                width: 110%;
                max-width: 110%;
            }
        }

        .items__caption{
            position: absolute;
            bottom: 20px;
            left: 20px;
            color: #fff;
            z-index: 1;
            h2{
                font-weight: 500;
                font-size: 16px;
                text-transform: uppercase;
                margin-bottom: 10px;
            }
        }
    }
}

@include component('BannerSingleWork'){
    display: block;
    overflow: hidden;
    height: auto;
    margin-top: 60px;
    position: relative;
    padding-bottom: 70px;
    padding-top: 70px;
    background: url(../img/bg-home.svg);
    background-size: cover;

    @include element('text'){
        display: block;
        width: 100%;
        //max-width: 1000px;
        margin: 70px auto;
        text-align: center;
        position: relative;
        z-index: 11;

        h1{
            color: #fff;
            font-weight: 900;
            text-transform: uppercase;
            //line-height: 80px;
            font-size: 90px;
            text-shadow: 5px 3px 10px rgba(71,71,71,.2);
            //text-align: center;

            @media(max-width: 600px){
                font-size: 120px;
            }
            @media(max-width: 400px){
                font-size: 90px;
            }
        }
    }
}



@include component('BoxSingleWork'){
    display: block;
    height: auto;
    width: 100%;
    overflow: hidden;
    position: relative;

    @include element('content'){
        display: flex;
        flex-wrap: wrap;
        margin: 30px auto;
        width: 100%;
        padding: 5px;
        //max-width: 1000px;

        @media(max-width: 768px){
            display: block;
        }
    }
    @include element('gallery'){
        display: block;
        margin-bottom: 10px;
        width: 100%;
    }
    @include element('items'){
        display: block;
        height: auto;
        width: 69%;
        float: left;
        box-sizing: border-box;
        margin-bottom: 2%;
        margin-right: 2%;
        position: relative;
        overflow: hidden;
        box-shadow: 0 0 11px 0 rgba(71,71,71,.1);
        padding: 10px;
        background: #fff;

        .content__text{
            padding: 5%;
            h2 {
                font-size: 24px;
                color: #32325d;
                font-weight: 100;
                text-align: left;
            }
            p {
                margin: 20px auto;
                font-size: 15px;
                line-height: 24px;
            }
        }

        &:nth-child(2n){
            width: 29%;
            margin-right: 0;
        }

        img{
            width: 100%;
            max-width: 100%;
            height: auto;
            transition: all .4s;
           
        }

        h2{
            font-weight: 900;
            font-size: 40px;
            text-align: center;
            color: #444;
            margin-bottom: 20px;
        }
    }
}